import { ref } from '@vue/composition-api'

// import router from 'vue-router';
import axiosClient from '@/helpers/axios'

// import router from '../router/index.js';

export default function useMesures() {
  const mesure = ref([])
  const loader = ref(false)
  const mesureProcess = ref(false)
  const mesureSuccess = ref(false)
  const mesures = ref([])
  const errors = ref('')

  // Liste des mesures
  const getMesures = async () => {
    loader.value = true
    await axiosClient.get('/unite_mesures').then(response => {
      if (response.data.success === true) {
        loader.value = false
        mesures.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Obtenir une mesure
  const getMesure = async slug => {
    const response = await axiosClient.get(`/unite_mesures/${slug}`)
    mesure.value = response.data.data
  }
  // Ajouter une mesure
  const createMesure = async data => {
    errors.value = ''
    mesureProcess.value = true
    await axiosClient.post('/unite_mesures', data)
      .then(response => {
        if (response.data.success === true) {
          mesureSuccess.value = true
          mesureProcess.value = false
          getMesures()
        }
      })
      .catch(error => {
        mesureProcess.value = false
        mesureSuccess.value = false
        errors.value = error.response.data.errors
      })
  }

  // Modifier une mesure
  const updateMesure = async data => {
    errors.value = ''
    mesureProcess.value = true
    await axiosClient.put(`/unite_mesures/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          mesureSuccess.value = true
          mesureProcess.value = false
          getMesures()
        }
      })
      .catch(error => {
        mesureProcess.value = false
        mesureSuccess.value = false

        errors.value = error.response.data.errors
      })
  }

  return {
    errors,
    mesure,
    mesures,
    getMesures,
    mesureProcess,
    createMesure,
    updateMesure,
    getMesure,
    loader,
    mesureSuccess,
  }
}
